.component-shape {
  overflow: hidden;
  position: relative;

  svg {
    width: 100%;
    height: 10vw;

    polygon {
      fill: #2d3095;
    }
  }

  img {
    display: block;
  }

  @media screen and (min-width: 813px) {}
}