.component-generic_block_1 {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .blockContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .blockContainer .blockImage {
    position: absolute;
    top: 40px;
    display: flex;
    width: 100%;
    max-width: 375px;
  }

  .blockContainer .blockImage img {
    width: 100%;
    max-height: 340px;
  }

  .blockContainer .blockContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blockContainer .blockContent .blockTitle {
    position: relative;
    width: 100%;
    font-size: 32px;
    color: #ffffff;
    font-family: "Corpid Bold", Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0 0 320px 0;
  }

  .blockContainer .blockContent .blockText {
    color: #CCCCCF;
    line-height: 2;
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 0px 40px;
    box-sizing: border-box;
  }

  .blockContainer .blockContent .storeButtons {
    display: flex;
    flex-direction: row;
    padding: 0px 30px;
    box-sizing: border-box;
    max-width: 520px;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .blockContainer .blockContent .storeButtons a {
    width: 46%;
    max-height: 46px;
    margin: 2px;
  }

  .blockContainer .blockContent .storeButtons a img {
    width: 100%;
  }

  .blockContainer .blockContent .button {
    margin: 0px 40px 40px 40px;
    width: calc(100% - 80px);
  }


  .button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 7px;
    min-width: 144px;
    max-width: 390px;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
  }

  .button.gradient {
    background: #FFBB00;
    background: linear-gradient(78deg, #FFBB00 0%, #FF6F00 100%);
    width: 100%;
    height: 86px;
    font-size: 27px;
    padding: 28px 20px;
  }

  @media screen and (min-width: 813px) {
    max-width: 1140px;
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    margin: 0 auto;


    .blockContainer {
      display: flex;
      flex-direction: row;
      flex-grow: 2;
    }

    .blockContainer .blockImage {
      position: relative;
      display: flex;
      width: 50%;
      max-width: 540px;
    }

    .blockContainer .blockImage img {
      width: 100%;
      max-height: 511px;
    }

    .blockContainer .blockContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 50%;
    }

    .blockContainer .blockContent .blockTitle {
      text-align: left;
      font-size: 50px;
      margin: 0;
    }

    .blockContainer .blockContent .blockText {
      text-align: left;
      font-size: 16px;
      padding: 0;
    }

    .blockContainer .blockContent .storeButtons {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;
    }

    .blockContainer .blockContent .storeButtons img {
      max-height: 145px;
    }

    .blockContainer .blockContent .button {
      display: none;
    }

    &.reverse .blockContainer {
      flex-direction: row-reverse;
    }
  }
}