.rich-text {
  ol {
    list-style: decimal;
    padding-inline-start: 2em;
  }

  ul {
    list-style: disc;
    padding-inline-start: 2em;
  }

  li {
    display: list-item;
    line-height: normal;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  h1 {
    font-size: 2em;
    font-weight: bold;
  }

  h2 {
    font-size: 1.5em;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17em;
    font-weight: bold;
  }

  h4 {
    font-size: 1em;
    font-weight: bold;
  }

  h5 {
    font-size: 0.83em;
    font-weight: bold;
  }

  h6 {
    font-size: 0.67em;
    font-weight: bold;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }

  .ql-align-left {
    text-align: left;
  }

  .ql-align-justify {
    text-align: justify;
  }

  .ql-indent-1 {
    text-indent: 1em;
  }

  .ql-indent-2 {
    text-indent: 2em;
  }

  .ql-indent-3 {
    text-indent: 3em;
  }

  .ql-indent-4 {
    text-indent: 4em;
  }

  .ql-indent-5 {
    text-indent: 5em;
  }
}