.component-main_banner_1 {
  position: relative;
  width: 100%;
  height: 650px;
  z-index: 9;
  overflow: hidden;

  .after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("./assets/img-bg-mobile.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .button {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 7px;
    min-width: 144px;
    max-width: 390px;
    font-size: 14px;
    text-align: center;
    box-sizing: border-box;
  }

  .button.primary {
    background: #FF004E;
  }

  .button.secondary {
    background: #0430D5;
  }

  .button.gradient {
    background: #FFBB00;
    background: linear-gradient(78deg, #FFBB00 0%, #FF6F00 100%);
    width: 100%;
    height: 86px;
    font-size: 27px;
    padding: 28px 20px;
  }

  .bannerContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .bannerContent .cover {
    width: 100%;
    height: 100%;
    background-color: rgba(25, 25, 25, 0.1);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .bannerContent .topBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 2;
    box-sizing: border-box;
    padding: 0px 30px;
  }

  .bannerContent .topBanner.hd {
    z-index: 10000;
  }

  .bannerContent .topBanner .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: absolute;
    background-color: transparent;
    top: 0px;
    transition: background-color .2s ease-in-out;
    padding: 15px 0px;
    z-index: 500;
  }

  .bannerContent .topBanner .header .logo {
    cursor: pointer;
    position: relative;
    width: 100%;
    text-decoration: none;
    margin-bottom: 22px;
    height: 41px;
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .header .logo img {
    width: 169px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0 auto;
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .header.fixedHeader {
    position: fixed;
    background-color: rgba(6, 0, 91, 0.9);
    background-color: linear-gradient(180deg, #06005b 0%, #06005b 100%);
    padding: 10px 0px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo {
    margin-bottom: 12px;
    height: 34px;
  }

  .bannerContent .topBanner .header.fixedHeader .logo img {
    width: 139px;
  }

  .bannerContent .topBanner .header.fixedHeader .topButtons a {
    padding: 6px 20px;
  }

  .bannerContent .topBanner .header .topButtons {
    display: flex;
    width: 100%;
    max-width: 344px;
    justify-content: space-between;
    align-items: flex-start;
  }

  .bannerContent .topBanner .header .topButtons a {
    transition: all .2s ease-in-out;
  }

  .bannerContent .topBanner .bannerTitle {
    color: #FFFFFF;
    font-size: 80px;
    text-align: center;
    width: 100%;
    margin: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerTitle.m150 {
    margin-top: 200px
  }

  .bannerContent .topBanner .bannerSubTitle {
    color: #FFFFFF;
    font-size: 45px;
    text-align: center;
    align-self: flex-start;
    margin: 0px;
    width: 100%;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner .bannerSubTitle.last {
    margin-bottom: 45px;
  }

  .bannerContent .topBanner .button {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  }

  .bannerContent .topBanner>.button {
    //margin-top: 240px;
  }

  @media screen and (min-width: 813px) {

    display: flex;
    justify-content: center;
    height: 800px;


    .after {
      background-image: url("./assets/img-bg.png");
    }

    .bannerContent {
      align-items: center;
      width: 100%;
    }

    .bannerContent .cover {
      width: 100%;
      height: 100%;
      background-color: rgba(25, 25, 25, 0.1);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    .bannerContent .topBanner {
      align-items: flex-start;
      max-width: 1140px;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
    }

    .bannerContent .topBanner .header {
      flex-direction: row;
      justify-content: space-between;
      position: relative !important;
      background-color: transparent !important;
      padding: 0px;
    }

    .bannerContent .topBanner .header .logo {
      width: auto;
      text-decoration: none;
      margin-bottom: 0;
      height: 41px;
    }

    .bannerContent .topBanner .header .logo img {
      position: relative;
      left: 0;
      margin: 0;
    }

    .bannerContent .topBanner .header .topButtons {
      max-width: 320px;
    }

    .bannerContent .topBanner .header.fixedHeader {
      padding: 0;
    }

    .bannerContent .topBanner .header.fixedHeader .topButtons a {
      padding: 12px 20px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo {
      height: 41px;
      margin-bottom: 0px;
    }

    .bannerContent .topBanner .header.fixedHeader .logo img {
      width: 169px;
    }

    .bannerContent .topBanner .bannerTitle,
    .bannerContent .topBanner .bannerSubTitle {
      max-width: 500px;
      text-align: left;
    }

    .bannerContent .topBanner>.button {
      margin-top: 20px;
      max-width: 320px;
    }
  }

}