@import "./styles/reset.scss";
@import "./styles/fonts.scss";
@import "./styles/flexUtils.scss";
@import "./components/_common/style.scss";

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a>img {
  pointer-events: none;
}